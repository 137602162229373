import IceSkate from "../assets/photo-gallery/iceskate.JPG";
import Infant1 from "../assets/photo-gallery/infant-1.jpeg";
import Infant2 from "../assets/photo-gallery/infant-2.jpeg";
import Infant3 from "../assets/photo-gallery/infant-3.jpg";
import Outside1 from "../assets/photo-gallery/outside.jpeg";
import Outside2 from "../assets/photo-gallery/outside-2.jpeg";
import Outside3 from "../assets/photo-gallery/outside3.jpeg";
import Paint from "../assets/photo-gallery/paint.jpeg";
import Plant from "../assets/photo-gallery/plant.jpeg";
import PreSchool from "../assets/photo-gallery/pre-school.jpeg";
import PreSchool2 from "../assets/photo-gallery/preschool-2.jpg";
import PreSchool3 from "../assets/photo-gallery/preschool.jpg";
import SchoolAge from "../assets/photo-gallery/schoolageClass-3.jpg";
import SchoolAge2 from "../assets/photo-gallery/schoolageClass.jpg";
import Science from "../assets/photo-gallery/science-2.jpg";
import Science2 from "../assets/photo-gallery/science-3.jpg";
import Science3 from "../assets/photo-gallery/science.jpg";
import Ship from "../assets/photo-gallery/ship.jpeg";
import Toddler from "../assets/photo-gallery/toddler.jpg";
import Bus from "../assets/photo-gallery/bus.jpg";
import Front from "../assets/photo-gallery/front.jpg";

export const photos = [{
    original: Infant1,
    thumbnail: Infant1,

},
{
    original: Infant2,
    thumbnail: Infant2,

},
{
    original: Infant3,
    thumbnail: Infant3,

},
{
    original: PreSchool,
    thumbnail: PreSchool,

},
{
    original: IceSkate,
    thumbnail: IceSkate,

},

{

    original: Paint,
    thumbnail: Paint,

},
{
    original: Plant,
    thumbnail: Plant,


},
{
    original: Science,
    thumbnail: Science,

},
{
    original: Science2,
    thumbnail: Science2,

},
{
    original: Science3,
    thumbnail: Science3,

},
{
    original: Outside1,
    thumbnail: Outside1,

},
{
    original: Outside2,
    thumbnail: Outside2,

},
{
    original: Outside3,
    thumbnail: Outside3,

},
{
    original: Ship,
    thumbnail: Ship,

},
{
    original: PreSchool2,
    thumbnail: PreSchool2,


},
{
    original: PreSchool3,
    thumbnail: PreSchool3,

},
{
    original: SchoolAge,
    thumbnail: SchoolAge,

},
{
    original: SchoolAge2,
    thumbnail: SchoolAge2,

},
{
    original: Toddler,
    thumbnail: Toddler,

},
{
    original: Bus,
    thumbnail: Bus,

},
{
    original: Front,
    thumbnail: Front,

},
];