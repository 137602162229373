import React from "react";
import { NavLink } from "react-router-dom";

export default function Cost() {
    return (
        <div>
            <div id="pageContainer">
                <span className="sectionHeader"> Tuition and Fees </span>
                <table>
                    <thead>
                        <tr >
                            <th></th>
                            <th>1st Child</th>
                            <th>2nd Child</th>
                            <th>Part Time</th>
                            <th>12.5hr a week</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                INFANTS
                                <br />6 weeks- 21months
                            </td>
                            <td>$346.00</td>
                            <td>$311.00</td>
                            <td>$259.00</td>
                            <td></td>
                        </tr>

                        <tr>
                            <td>
                                TODDLER <br />
                                21 months-3 years{" "}
                            </td>
                            <td>$307.00</td>
                            <td>$276.00</td>
                            <td>$230.00</td>
                            <td>$145.00**</td>
                        </tr>

                        <tr>
                            <td>
                                PRESCHOOL <br />3 years-5 years{" "}
                            </td>
                            <td>$269.00</td>
                            <td>$242.00</td>
                            <td>$202.00</td>
                            <td>$125.00**</td>
                        </tr>
                        <tr>
                            <td>
                                SCHOOL AGE <br />
                            </td>
                            <td>$269.00</td>
                            <td>$242.00</td>
                            <td>$202.00</td>

                            <td></td>
                        </tr>
                        <tr>
                            <td>Before and After</td>
                            <td>$119.00</td>
                            <td>$107.00</td>
                            <td>$95.00</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Before </td>
                            <td>$61.00</td>
                            <td>$55.00 </td>
                            <td>$52.00</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>After </td>
                            <td>$84.00</td>
                            <td>$76.60 </td>
                            <td>$76.00</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <h2>RATE WILL VERY BASED ON TRANSPORTATION NEEDS</h2>
                <p id="rateExplanation">
                    <span id="parHeader"> Registration Fee: </span>
                    $100.00 per family *Due upon registration & any lengthy
                    withdraw and re-enroll *non-refundable <br />
                    <span id="parHeader"> Preschool: </span> 3 years old and
                    Potty trained.
                    <br />
                    <span id="parHeader"> Full-Time: </span> 4 or 5 days of
                    child care Monday-Friday consisting of 5 or more hours per
                    day
                    <br />
                    <span id="parHeader"> Part-Time: </span> Children who
                    receive 5 or less hours of child care per day or are
                    enrolled 2-3 days a week
                    <br />
                    <span id="parHeader"> Snow Days:</span> As a courtesy to our
                    families, you may drop off your school aged child (up to the
                    age of 12) and be charged the daily rate($84). This service has
                    limited enrollment. PLEASE ALWAYS CALL AHEAD FOR
                    CONFIRMATION. For Families already enrolled in our Before &
                    After care Program the fee is $40.00 a day.
                    <br />
                    <span id="parHeader"> Late Pick Up Fee: </span>$30 per child
                    every 10 minutes here past 5:30 pm Monday--Thursday & 5:00pm
                    Friday.
                    <br />
                    <span id="parHeader"> 1st Child: </span>1st child means the youngest
                    enrolled in the family.
                    <br />
                    <span id="parHeader"> **2 ½ Hour Preschool: </span>
                    Monday - Friday, Preschool 9-11:30am for $125, Toddlers (21 month andover) 8:45-11:15am for $140
                </p>
                <br />
                <h2>
                    ***Hours of Operation: Monday thru Thursday 6:30AM—5:30PM
                    Friday 6.30AM—5:15PM*** Effective 10/21/2024

                </h2>
                <br />
            </div>
            <div>
                <p className="sectionHeaderPrograms">
                    <NavLink to="/tuition">
                        {" "}
                        How to Pay Tuition
                        <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"></i>
                        <i
                            className="fa fa-angle-double-right"
                            aria-hidden="true"></i>
                    </NavLink>
                </p>
            </div>
        </div>
    );
}
